<template>
    <div class="buttons">
        <v-row justify="center" class="mt-1">
            <small style="font-weight: bold;font-size: 20px;color:#0D47A1">ALTERAR STATUS DA PRODUÇÃO PARA:</small>
        </v-row>

        <v-row class="pr-0 pl-1 pt-0 pb-3 ga-0">
            <v-col cols="6">
                <v-btn id="btn-style" data-cy="btn-produzindo" color="#43A047" height="45" stacked size="small" @click="click_produzindo">
                    PRODUZINDO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#1E88E5"  data-cy="btn-manutencao" height="45" stacked size="small" @click="click_manutencao">
                    MANUTENÇÃO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#F9A825" data-cy="btn-setup" height="45" stacked size="small" @click="click_setup">
                    SETUP
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#D50000" data-cy="btn-parada" height="45" stacked size="small" @click="motivo_de_parada">
                    PARADO
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { useAppStore } from '../../stores/app';
    import { useAppApontamento } from '../../stores/appApontamento';
    import { useAppMotivoDeParada } from '../../stores/appMotivosdeParada';

    export default {
        name: "eventosApontamento",
        data() { return { awesome: false } },
        computed: { appStore() { return useAppStore(); } },
        methods: {
            async click_manutencao() {
                useAppStore().load_actions = {message : "ABRINDO APONTAMENTO DE MANUTENÇÃO", showMessage : true};
                
                const message = { message: 'MANUTENÇÃO INICIADA', color: 'secondary', showMessage: true }
                
                this.appStore.apontamento_atual.tipo = 'Parada';
                
                this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = 'Manutenção';
                
                const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup)

                const json_update = {
                    tipo: this.appStore.apontamento_atual.tipo,
                    motivo_de_parada : mp_setup.name,
                }

                await useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
                
                useAppStore().load_actions.showMessage = false;
                useAppStore().message =  message
            },

            async click_setup() {
                useAppStore().load_actions = {message : "ABRINDO APONTAMENTO DE SETUP", showMessage : true};

                this.appStore.apontamento_atual.tipo = 'Parada';
                this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = 'Setup';
                const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup);

                const json_update = {
                    tipo: this.appStore.apontamento_atual.tipo,
                    motivo_de_parada: mp_setup.name,
                };

                useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update);
                
                useAppStore().load_actions.showMessage = false;
                
                this.awesome = true; 
            },

            async motivo_de_parada() {
                useAppStore().load_actions = {message : "ABRINDO APONTAMENTO DE MOTIVO DE PARADA", showMessage : true};
                
                this.appStore.apontamento_atual.tipo = 'Parada';
                
                this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = null;

                const json_update = {
                    tipo: this.appStore.apontamento_atual.tipo,
                    setup : 0,
                    manutencao : 0,
                    motivo_de_parada : null,
                    desc_motivo_parada : null
                }
                
                await useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
                
                useAppStore().load_actions.showMessage = false
            },

            async click_produzindo() {
                useAppStore().load_actions = {message : "ABRINDO APONTAMENTO", showMessage : true};

                if (this.appStore.apontamento_atual.name) {
                    this.appStore.apontamento_atual.tipo = 'Produção';
                    await useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, { tipo: this.appStore.apontamento_atual.tipo })
                    useAppStore().load_actions.showMessage = false
                } else {
                    await useAppApontamento().abrir_apontamento(this.appStore.maquina_sel.id)
                    useAppStore().load_actions.showMessage = false
                }
            }
        },
    }
</script>

<style scoped>
    #btn-style {
        width: 98%;
        min-width: 100px;
        color: white;
        font-weight: bold;
    }
</style>