<template>
    <v-dialog v-model="appCharts.showModalMp" transition="dialog-bottom-transition" fullscreen max-width="3200px">
        <v-container fluid>
            <v-card style="height:900px">
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.showModalMp = false"></v-btn>
                    <v-menu transition="scale-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props">
                                Recarregar em: {{ timeSelect }}
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                                <v-list-item-title @click="onclickTime(item.title)">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn class="" @click="dialog = true">Filtrar Por Data</v-btn>
                    <v-dialog v-model="dialog" max-width="600" height="600">
                        <v-card class="pt-1 pb-1 pl-1 pr-1" max-width="800" height="600">
                            <v-container fluid>
                                <v-row class="mt-0 mb-0 pt-0 pb-0">
                                    <v-col cols='12' class="mt-0 mb-0 pt-0 pb-0">
                                        <h3>Filtrado por:</h3>
                                        <br>
                                        <v-row justify="space-between">
                                            <v-col cols="5">
                                                <ejs-calendar 
                                                    :min="minDate" 
                                                    :max="maxDate" 
                                                    :value="startDate" 
                                                    @change="onStartDateChange">
                                                </ejs-calendar>
                                            </v-col>
                                            <v-col cols="6" style="margin-left: 13px;">
                                                <ejs-calendar 
                                                    :min="minDate" 
                                                    :max="maxDate" 
                                                    :value="endDate" 
                                                    @change="onEndDateChange">
                                                </ejs-calendar>
                                            </v-col>
                                        </v-row>
                                        <span id="date_range_label">Período selecionado: {{ startDate && endDate ? startDate.toLocaleDateString() + ' a ' + endDate.toLocaleDateString() : 'não selecionado' }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0 mb-0 pt-0 pb-0">
                                    <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                        <div class="control-styles" style="padding-top: 30px">
                                            <label class="h4">Por Tempo: </label>
                                            <ejs-multiselect id='sample-list3' :dataSource='valores'
                                                :mode='delimiterMode' :placeholder='tempo'></ejs-multiselect>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="mt-2 mb-0 pt-0 pb-0">
                                    <v-btn color="#04b834" @click='onclickAplicar'>Aplicar</v-btn>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-dialog>
                    <v-btn class="ml-2" style="background-color: #04b834;" color="white"
                        @click='onclickAplicar'>Aplicar</v-btn>
                </v-toolbar>
                <Pareto_motivo_de_parada />
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import Pareto_motivo_de_parada from "./pareto_motivo_de_parada.vue";
import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-vue-calendars/styles/material.css';

export default {
    name: "motivo_de_parada",
    components: {
        'ejs-calendar': CalendarComponent,
        Pareto_motivo_de_parada,
        'ejs-multiselect': MultiSelectComponent,
    },

    data: () => ({
        minDate: new Date(2024, 1, 1), // Defina a data mínima conforme necessário
        maxDate: new Date(2026, 1, 1), // Defina a data máxima conforme necessário
        startDate: null,
        endDate: null,
        dialog: false,
        items: [
            { title: 'OFF' },
            { title: 'AUTO' },
            { title: '5S' },
            { title: '10S' },
            { title: '30S' },
            { title: '1M' },
            { title: '5M' },
            { title: '10M' },
            { title: '15M' },
            { title: '30M' },
            { title: '1H' },
            { title: '2H' },
            { title: '1D' },
        ],
        valores: [
            'OFF',
            'AUTO',
            '5 DIAS',
            '15 DIAS',
            '30 DIAS',
            '1 SEMANA',
            '5 SEMANAS',
            '10 SEMANAS',
            '15 SEMANAS',
            '30 SEMANAS',
            '1 MÊS',
            '2 MESES',
            '1 ANO',
        ],
        tempo: 'filtrar',
    }),

    computed: {
        appCharts() {
            return useAppCharts();
        }
    },

    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        },

        onclickAplicar() {
            alert('Filtros aplicados com sucesso!');
        },

        onStartDateChange(args) {
            this.startDate = args.value;
        },

        onEndDateChange(args) {
            this.endDate = args.value;
        },
    }
}
</script>